<!--
 * @Author: jiang
 * @Date: 2021-07-02 09:55:23
 * @Description:
-->
<template>
  <el-form
    class="b-form-info"
    label-width="100px"
    label-suffix="："
  >
    <el-form-item label="类型">
      <div>{{ data.type_name }}</div>
    </el-form-item>
    <el-form-item label="人员姓名">
      <div>{{ data.target_user_name }}</div>
    </el-form-item>
    <el-form-item label="原单位名称">
      <div>{{ data.primary_department_name }}</div>
    </el-form-item>
    <el-form-item label="开始时间">
      <div>{{ data.start_time }}</div>
    </el-form-item>
    <el-form-item label="结束时间">
      <div>{{ data.end_time }}</div>
    </el-form-item>
    <el-form-item label="销假时间">
      <div>{{ data.destruction_leave_date }}</div>
    </el-form-item>
    <el-form-item label="请假事由">
      <div>{{ data.leave_reason }}</div>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    data: {},
  },
}
</script>
